.footer {
    background-color: $color-dark;
    color: $color-white;
    padding-top: 80px;

    &__infos {
        display: flex;
        justify-content: space-between;
    }

    &__coordonnees {
        width: 300px;
    }

    &__horaires {
        width: 385px;
        margin-left: -45px;
    }

    &__title {
        font-weight: 700;
        font-size: toRem(22);
        padding-bottom: 15px;
        line-height: toRem(25);
    }

    &__text {
        font-weight: 400;
        font-size: toRem(16);
        line-height: toRem(20);
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: 15px;

        a {
            color: $color-dark;
            height: 45px;
            width: 270px;
            margin: 0;
            border: none;

            svg {
                fill: $color-dark;
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }

            &:hover, &:focus {
                background-color: $color-main;
                color: $color-white;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    &__phone {
        background-color: $color-third;
    }

    &__contact {
        background-color: $color-white;
    }

    &__menu {
        padding: 60px 0;

        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 50px;
            width: 100%;
        }

        li {
            position: relative;
            font-weight: 300;
            font-size: toRem(16);

            a {
                &:hover, &:focus {
                    color: $color-second;
                }
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {

    .footer {
        &__coordonnees {
            width: 240px;
        }

        &__horaires {
            margin-left: -35px;
        }

        &__buttons {
            a {
                width: 240px;
            }
        }

        &__menu ul {
            gap: 40px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {

    .footer {
        padding-top: 60px;

        &__infos {
            flex-direction: column;
            gap: 30px;
            align-items: center;
        }

        &__horaires {
            margin-left: 0;
        }

        &__buttons {
            flex-direction: row;
            gap: 20px;
        }

        &__text, &__title {
            text-align: center;
        }

        &__menu ul {
            gap: 15px 40px;
            justify-content: center;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &__horaires {
            width: 300px;
        }

        &__buttons {
            flex-direction: column;
        }
    }
}