.search-container {
    display: flex;
    position: relative;
    align-items: center;
    z-index: 1;

    .searchInput {
        padding: 24px 40px 24px 24px;
        border: none!important;
        box-sizing: border-box;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        box-shadow: $shadow;
        border-radius: 50px;

        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }

    .search-svg {
        width: 70px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
            width: 15px;
            height: 15px;
            fill: $color-dark;
            transition: all $duration;
        }

        &:hover {
            svg {
                fill: $color-main;
            }
        }
    }
}

.searchResult {
    position: relative;
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    background: $color-white;
    top: -35px;
    border-radius: 0 0 15px 15px;
    box-shadow: $shadow;

    li {
        position: relative;
        border-bottom: 1px solid $color-light;

        a {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 15px 24px;
            transition: all $duration;
        }

        .resultCPT {
            position: absolute;
            right: 25px;
            top: 50%;
            pointer-events: none;
            color: $color-main;
            transform: translate(0,-50%);
            transition: all $duration;
        }

        &.no-results {
            span {
                display: flex;
                width: 100%;
                height: 100%;
                padding: 15px 24px;
                color: $color-dark;
            }
        }

        &:not(.no-results) {
            a:hover {
                padding-left: 35px;
                background: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }

            &.selected,
            &:hover {
                .resultCPT {
                    color: $color-white;
                }
            }
        }

        &:last-child {
            border-bottom:0;
        }

        &.selected {
            a {
                padding-left: 35px;
                background: $color-main;
                color: $color-white;

                &::after {
                    color: $color-white;
                }
            }
        }

        &.show-all a {
            text-transform: uppercase;
            font-size: $font-size--text-small;
            font-weight: $font-weight-bold;
            border-radius: 0 0 15px 15px;

            &::after {
                content: "\f344";
                position: relative;
                display: flex;
                align-items: center;
                height: 100%;
                margin-left: 10px;
                font: normal 18px/1 dashicons;
                color: $color-text;
                transition: all $duration;
            }
        }
    }
}


body.search-open {
    overflow: hidden;
}

body.search {
    .breadcrumb {
        display: none;
    }
    .container--search {
        padding-top: 20px;
    }
}

.search-popup {
    position: fixed;
    top: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: $color-white;
    transition: all 0.65s;

    &:before {
        position: absolute;
        content: "";
        width: 565px;
        height: 460px;
        right: -150px;
        top: 620px;
        background-image: url(/wp-content/themes/st-colomban/assets/src/images/sprite-images/home/bg_popup_recherche.svg);
        background-repeat: no-repeat;
        z-index: 0;
    }

    .container {
        position: relative;
        height: 100%;
        

        .search-popup__content {
            position: relative;
            top: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;   
        }

        label {
            position: relative;
            display: flex;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto 20px auto;
            padding-bottom: 0;
            border: 0;
            font-family: $font-family;
            font-weight: 800;
            font-size: toRem(50);
            color: $color-dark;
            text-transform: none;
        }

        .search-container {
            display: flex;
            position: relative;
            align-items: center;
            width: calc(100% - 200px);
            margin: 0 auto;

            .searchInput {
                background: $color-white;
                border-color: $color-white;
                border-radius: 50px;
                box-shadow: $shadow;
            }
        }

        .searchResult {
            width: calc(100% - 200px);
            left: 100px;
        }
    }

    .search-close {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        top: 25px;
        right: 0;
        width: 40px;
        height: 40px;
        padding: 0;
        background: $color-second;
        border: none;
        border-radius: 50%;
        transition: all $duration;
        
        svg {
            width: 25px;
            height: 25px;
            fill: $color-dark;
            transition: all $duration;
        }

        &:hover {
            background: $color-fifth;

            svg {
                fill: $color-dark;
            }
        }
    }
}


//======================================================================================================
// Search Page
//======================================================================================================
.filter {
    .search-container {
        .search-svg {
            border-top-right-radius: 50px;
            border-bottom-right-radius: 50px;
            transition: all $duration;

            &:hover {
                svg {
                    fill: $color-main;
                }
            }

            svg {
                fill: $color-dark;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1500 - Responsive pour les feuilles décorative
@media screen and (max-width: 1500px) {
    .search-popup::before {
        left: calc(50% + 355px);
    }
}

// 1200
@media screen and (max-width: $large) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: calc(100% - 140px);
    }
    .search-popup .container .searchResult {
        left: 70px;
        width: calc(100% - 140px);
    }
    .search-popup::before {
        left: calc(50% + 175px);
        top: 500px;
    }
}


// 960
@media screen and (max-width: $medium) {

    .search-popup .container .search-container,
    .search-popup .container label {
        width: 100%;
    }
    .search-popup .container .searchResult {
        left: 0;
        width: 100%;
    }

    .search-popup .container .search-popup__content {
        top: 20%;
    }

    .search-popup::before {
        width: 465px;
        height: 380px;
        background-size: 465px;
        left: calc(50% + 130px);
        top: 400px;
    }
}


// 640
@media screen and (max-width: $small) {

    .searchResult li .resultCPT {
        display: none;
    }
    .search-popup .container .search-popup__content {
        top: 15%;
    }

    .search-popup .container label {
        @include font-size(40);
    }

    .search-popup::before {
        width: 350px;
        height: 285px;
        background-size: 350px;
        left: calc(50% + 70px);
        top: 300px;
    }
    .search-container .search-svg {
        right: 10px;
        width: 40px;
        background: white;
        border-radius: 50px;
    }
}
