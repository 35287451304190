//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;
    margin-top: 100px;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation, &--pagination {
            @include absolute(0, 0, 0, 0);
            height: 100%;
            pointer-events: none
        }
    }

    .slideshow {
        @include size(100%, 575px);

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            @include size(100%);
        }

        &__container {
            display: inherit;
            position: relative;
            @include size(100%);
        }

        &__content {
            @include flex($direction: column);
            box-sizing: border-box;
            @include absolute($right: 30px, $bottom: 50px);
            @include size(500px, auto);
            padding: 20px;
            background: $color-bg-caption;
            color: $color-caption;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Navigation (arrow)
        &__navigation {
    
            &__btn {
                pointer-events: auto;
                width: 40px;
                height: 40px;
                margin: 0;
                padding: 0;
                background: $color-fifth;
                border: 0;
                top: unset;
                right: 15px;
                border-radius: 50px;
                bottom: 50px;
                transition: all $duration ease-in-out;
    
                &::after {
                    display: none;
                }

                svg {
                    width: 100%;
                    height: 100%;
                    fill: $color-dark;
                    transition: all $duration ease-in-out;
                }
    
                &:hover, &:focus {
                    background-color: $color-third;
                }

                &--prev {
                    position: absolute;
                    left: unset;
                    bottom: 105px;
                }
            }
        }
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================

.access {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 250px;
        height: 205px;
        right: -100px;
        bottom: -188px;
        background-image: url(/wp-content/themes/st-colomban/assets/src/images/sprite-images/home/bg_ar.svg);
        background-repeat: no-repeat;
        z-index: 1;
    }

    &__blocs {
        display: flex;
        justify-content: space-between;
        margin: 60px 25px 48px;
    }

    &__bloc {
        width: 120px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        text-align: center;
        font-weight: 700;
        font-size: toRem(14);
        text-transform: uppercase;
        color: $color-dark;
        position: relative;
        transition: all $duration ease-in-out;

        span {
            font-weight: 300;
        }

        &:nth-child(2n) {
            .access__ligne {
                fill: $color-fifth;
            }
        }

        &:hover, &:focus {
            .access__ligne {
                margin: 5px 0 20px;
                fill: $color-third;

                &--path {
                    fill: $color-third;
                }
            }
        }
    }

    &__ligne {
        width: 40px;
        height: 10px;
        margin: 10px 0 20px;
        transition: all $duration ease-in-out;

        &--path {
            transition: all $duration ease-in-out;
        }
    }
}

//======================================================================================================
// Actualités
//======================================================================================================

.news {
    padding: 60px 0 80px;
    background-color: $color-main;
    color: $color-white;
    position: relative;
    overflow: hidden;

    &:before {
        position: absolute;
        content: "";
        width: 550px;
        height: 235px;
        left: -180px;
        bottom: -45px;
        background-image: url(/wp-content/themes/st-colomban/assets/src/images/sprite-images/home/bg_actus.svg);
        background-repeat: no-repeat;
        z-index: 1;
    }

    &__container {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            right: -375px;
            top: 130px;
            width: 520px;
            height: 340px;
            background: linear-gradient(270deg, #255C8B 0%, rgba(37, 92, 139, 0) 100%);
            z-index: 2;
            transition: all $duration ease-in-out;
        }
    }

    &__title {
        @extend %homeTitle;
        color: $color-third;
        margin-left: 4px;
    }

    &__subtitle {
        @extend %homeSubTitle;
        margin-top: -15px;
    }

    &__swiper {
        margin-top: 22px;
        max-width: unset;
        width: 1700px;
    }

    &__link {
        width: 520px!important;
        height: 320px!important;
        border-radius: $border-radius;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(250.55deg, rgba(0, 21, 33, 0) 0%, rgba(0, 21, 33, 0.9) 100%);
            border-radius: $border-radius;
            z-index: 0;
            transition: all $duration ease-in-out;
        }

        &.swiper-slide {
            pointer-events: none;

            &-active, &-next {
                pointer-events: auto;
            }
        }

        &:hover, &:focus {
            color: $color-white;

            .news__title--small {
                padding-bottom: 30px;

                &:after {
                    width: 40px;
                }
            }
        }
    }

    &__image {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            object-fit: cover;
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-light;
            background-position: center;
            border-radius: $border-radius;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        position: absolute;
        left: 30px;
        bottom: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__title--small {
        @extend %homeTitleSmall;
        width: 330px;
        position: relative;
        transition: all $duration ease-in-out;
        box-sizing: border-box;

        &:after {
            position: absolute;
            content: "";
            width: 0;
            height: 10px;
            left: 0;
            bottom: 5px;
            mask-image: url(/wp-content/themes/st-colomban/assets/src/images/sprite-images/Pictos/ligne_ar.svg);
            mask-repeat: no-repeat;
            mask-size: 40px;
            background-color: $color-fifth; 
            transition: all 0.5s ease-out;
        }
    }

    &__introduction {
        font-weight: 400;
        font-size: toRem(16);
        line-height: toRem(20);
    }

    &__navigation {
        display: flex;
        gap: 15px;
        flex-direction: column;
        position: absolute;
        top: 335px;
        right: 65px;
        z-index: 3;

        &--prev, &--next {
            // Enlever style par défaut des flèches
            width: 40px!important;
            height: 40px!important;
            position: relative!important;
            top: unset!important;
            left: unset!important;
            right: unset!important;
            bottom: unset!important;
            margin-top: 0!important;
            background-color: $color-third;
            border-radius: 50%;
            transition: all $duration ease-in-out;
            cursor: pointer;
            z-index: 2!important;
            

            &:after {
                display: none;
            }

            svg {
                fill: $color-dark;
                height: 100%;
                width: 100%;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-fifth;
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 50px;
    }

    &__button {
        width: 320px;
        background-color: $color-white;
        color: $color-dark;
        border-color: $color-white;
        margin: 0;

        &:hover, &:focus {
            background-color: $color-dark;
            color: $color-white;
            border-color: $color-dark;
        }
    }
}

//======================================================================================================
// Agenda
//======================================================================================================

.home-events {
    margin-top: 90px;

    &__container {
        display: flex;
        gap: 60px;
        position: relative;
    }

    &__infos {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &__header {
        margin: 25px 0 15px;
    }

    &__title {
        @extend %homeTitle;
        color: $color-second;
    }

    &__subtitle {
        @extend %homeSubTitle;
        margin-top: -15px;
    }

    &__swiper {
        &--image {
            width: 670px;
            height: 380px;
            min-width: 630px;
            margin: 0!important;
        }

        &--content {
            max-width: 406px;
            height: 65px!important;
            margin-bottom: 45px!important;
        }
    }

    &__link {
        display: flex;
        justify-content: flex-end;
        position: relative;
        background: $color-white;

        &:hover, &:focus {
            .home-events__image img {
                transform: scale(1.1);
            }

            .home-events__date p {
                background-color: $color-third;
            }
        }
    }

    &__image {
        width: 630px;
        height: 100%;
        border-radius: $border-radius;
        position: relative;
        overflow: hidden;

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-light;
            background-position: center;
            width: 630px;
            height: 100%;
            border-radius: $border-radius;
        }

        img {
            width: 100%;
            height: 100%;
            border-radius: $border-radius;
            object-fit: cover;
            transition: all 0.75s ease-in-out;
        }
    }

    &__date {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: absolute;
        left: 0;
        bottom: 30px;

        p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 80px;
            background-color: $color-second;
            border-radius: 50%;
            color: $color-dark;
            font-weight: 700;
            font-size: toRem(25);
            transition: all $duration ease-in-out;

            span {
                font-weight: 300;
                font-size: toRem(20);
                text-transform: uppercase;
                margin-top: -10px;
                margin-bottom: 5px;
            }
        }
    }

    &__title--small {
        @extend %homeTitleSmall;
        background-color: $color-white;
        width: 406px;
        height: 40px;
    }

    &__buttons {
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
    }

    &__button {
        width: 320px;
        margin: 0;

        &:last-child {
            background-color: $color-fifth;
            color: $color-dark;
            border-color: $color-fifth;
        }

        &:first-child {
            background-color: $color-dark;
            color: $color-white;
            border-color: $color-dark;
        }

        &:hover, &:focus {
            background-color: $color-main;
            color: $color-white;
            border-color: $color-main;
        }
    }

    &__navigation {
        display: flex;
        gap: 15px;
        flex-direction: column;
        position: absolute;
        left: 665px;
        top: 30px;
        z-index: 1;

        &--prev, &--next {
            // Enlever style par défaut des flèches
            width: 40px!important;
            height: 40px!important;
            position: relative!important;
            top: unset!important;
            left: unset!important;
            right: unset!important;
            bottom: unset!important;
            margin-top: 0!important;
            background-color: $color-fifth;
            border-radius: 50%;
            transition: all $duration ease-in-out;
            cursor: pointer;
            z-index: 2!important;
            

            &:after {
                display: none;
            }

            svg {
                fill: $color-dark;
                height: 100%;
                width: 100%;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-third;
            }
        }
    }
}

//======================================================================================================
// Social Wall
//======================================================================================================

.social {
    margin: 100px 0 93px;
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 565px;
        height: 460px;
        right: -175px;
        top: -30px;
        background-image: url(/wp-content/themes/st-colomban/assets/src/images/sprite-images/home/bg_sw.svg);
        background-repeat: no-repeat;
        z-index: -1;
        pointer-events: none;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
    }

    &__header {
        width: 100%;
    }

    &__title {
        @extend %homeTitle;
        color: $color-fifth;
        margin-left: 4px;
    }

    &__subtitle {
        @extend %homeSubTitle;
        margin-top: -15px;
    }

    &__wall {
        display: flex;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        gap: 30px;
        margin-left: 35px;
        margin-top: 70px;
        position: relative;
        z-index: 1;

        .access {

            &__bloc {
                height: 160px;

                &:hover, &:focus {
                    .access__ligne {

                        &--path {
                            fill: none;
                            stroke: $color-third;
                        }
                    }
                }

                &--push {
                    .access__ligne {
                        fill: $color-second;

                        &--path {
                            fill: $color-second;
                            stroke: none;
                        }
                    }
                    
                    &:hover, &:focus {
                        .access__ligne {
                            fill: $color-third;
    
                            &--path {
                                fill: $color-third;
                                stroke: none;
                            }
                        }
                    }
                }
            }

            &__ligne {
                fill: $color-fifth;
            }
        }
    }

    &__rs {
        position: absolute;
        display: flex;
        gap: 10px;
        left: calc(50% + 275px);
        top: 55px;
    }

    &__button {
        width: 40px;
        height: 40px;
        background-color: $color-dark;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all $duration ease-in-out;

        svg {
            width: 100%;
            height: 100%;
            fill: $color-white;
        }

        &:hover, &:focus {
            background-color: $color-main;
        }
    }

    #ff-stream-1 {
        background-color: transparent !important;
        width: 1000px;
        margin-left: -20px;
        min-height: unset!important;
        padding-bottom: 0;
        z-index: 0;
        
        .ff-header, .ff-loadmore-wrapper {
            display: none;
        }

        .ff-stream-wrapper {
            padding: 0;
            margin: 0;
            width: 100%;
        }

        .ff-item {
            box-shadow: (0px 0px 20px rgba(0, 0, 0, 0.1));
            border-radius: $border-radius;

            .ff-img-holder {
                border-radius: $border-radius $border-radius 0 0;
                height: 215px;
            }

            .ff-item-bar {
                border-color: $color-dark;
            }
        }

        .picture-item__inner {
            border-radius: $border-radius;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1500 - Responsive pour les feuilles décorative
@media screen and (max-width: 1500px) {
    .access::before {
        left: calc(50% + 600px);
    }

    .news::before {
        left: calc(50% + -930px);
    }

    .social::before {
        left: calc(50% + 355px);
    }
}

// 1200
@media screen and (max-width: $large) {
    .home-slideshow {
        .slideshow {
            height: 460px;

            &__navigation {
    
                &__btn {
                    right: 10px;
                    bottom: 40px;
    
                    &--prev {
                        bottom: 90px;
                    }
                }
            }
        }
    }

    .access {
        &:before {
            left: calc(50% + 450px);
            bottom: -208px;
        }

        &__blocs {
            margin: 60px 35px 68px;
        }
    }

    .news {
        &:before, &:after {
            left: calc(50% - 770px);
        }

        &__container {
            &:before {
                height: 270px;
                right: -120px;
                width: 255px;
            }
        }

        &__header {
            margin-left: 35px;
        }
    
        &__link {
            width: 420px!important;
            height: 260px!important;

            &:hover, &:focus {
                .news__title--small {
                    padding-bottom: 25px;

                    &:before {
                        width: 30px;
                    }
                }
            }
        }
    
        &__content {
            left: 20px;
            bottom: 20px;
        }

        &__title--small {
            &:before {
                mask-size: 30px;
            }
        }
    
        &__introduction {
            font-size: toRem(14);
        }
    
        &__navigation {
            gap: 10px;
            right: 30px;
            top: 280px;
        }
    
        &__buttons {
            margin-top: 40px;
        }
    
        &__button {
            width: 300px;
        }
    }

    .home-events {
        margin-top: 80px;

        &__header {
            margin: 20px 0 15px;
        }
    
        &__swiper {
            &--image {
                width: 550px;
                height: 360px;
                min-width: 500px;
            }
    
            &--content {
                max-width: 340px;
                margin-bottom: 35px !important;
            }
        }
    
        &__image {
            width: 500px;
    
            &--notimg {
                width: 500px;
            }
        }
    
        &__date {
            bottom: 40px;
        }
    
        &__buttons {
            gap: 15px;
        }
    
        &__button {
            width: 300px;
        }
    
        &__navigation {
            gap: 10px;
            left: 530px;
        }
    }

    .social {
        margin: 100px 0 98px;

        &:before {
            left: calc(50% + 245px);
        }
    
        &__buttons {
            margin-left: 20px;
            margin-top: 55px;
            gap: 15px;

            .access {

                &__bloc {
                    height: 150px;
                    margin-top: 0;
                }

                &__ligne {
                    margin: 10px 0;
                }
            }
        }

        &__rs {
            left: calc(50% + 210px);
        }
    
        &__button {
            width: 40px;
            height: 40px;
        }
    
        #ff-stream-1 {
            width: 820px;
            margin-left: -20px;

            .ff-item {
                .ff-img-holder {
                    height: 175px;
                }
            }
            
            .ff-item-cont {
                height: 310px!important;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .home-slideshow {
        position: relative;
        margin-top: 0;

        .slideshow {
            height: 380px;

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }

            &__navigation {
    
                &__btn {
                    bottom: 30px;
    
                    &--prev {
                        bottom: 30px;
                        right: 60px;
                    }
                }
            }
        }
    }

    .access {
        &:before {
            bottom: -213px;
            left: calc(50% + 310px);
        }

        &__blocs {
            margin: 60px 50px 68px;
            flex-wrap: wrap;
            gap: 48px 80px;
        }
    }

    .news {
        &:before, &:after {
            left: calc(50% - 650px);
        }

        &__container {
            &:before {
                width: 220px;
                height: 210px;
                right: -170px;
            }
        }

        &__header {
            margin-left: -5px;
        }
    
        &__link {
            width: 300px!important;
            height: 200px!important;

            &:hover, &:focus {
                .news__title--small {
                    padding-bottom: 20px;

                    &:before {
                        width: 20px;
                        bottom: 0;
                    }
                }
            }
        }

        &__content {
            max-width: 260px;
        }

        &__title--small {
            width: 100%;

            &:before {
                mask-size: 20px;
            }
        }
    
        &__introduction {
            font-size: toRem(12);
            line-height: toRem(15);
        }
    
        &__navigation {
            right: 10px;
            top: 50px;
            flex-direction: row;
        }
    }

    .home-events {
        margin-top: 60px;

        &__container {
            flex-direction: column;
            gap: 20px;
            padding-top: 130px;
        }

        &__swiper {
            &--image {
                width: 620px;
                height: 360px;
                min-width: 500px;
            }
    
            &--content {
                margin-bottom: 20px !important;
                max-width: 100%;
                margin-left: 40px !important;
                height: 25px !important;
            }
        }
    
        &__image {
            width: 580px;
    
            &--notimg {
                width: 580px;
            }
        }

        &__date {
            bottom: 30px;
        }

        &__header {
            position: absolute;
            top: 0;
            margin: 0;
            left: 50px;
        }

        &__title--small {
            width: 100%;
            height: 100%;
        }

        &__navigation {
            left: 540px;
            top: 50px;
            flex-direction: row;
        }

        &__buttons {
            gap: 20px;
            flex-direction: row-reverse;
            margin-left: 40px;
        }

        &__button {
            width: 280px;
        }
    }

    .social {
        margin: 79px 0 77px;

        &__wall {
            flex-direction: column;
        }

        &:before {
            left: calc(50% + 185px);
            width: 465px;
            height: 380px;
            background-size: 465px;
            top: -50px;
        }

        &__container {
            position: relative;
        }

        &__rs {
            left: unset;
            right: 10px;
        }
    
        &__buttons {
            width: 370px;
            height: 100px;
            background-color: $color-light;
            border-radius: 10px;
            flex-direction: row;
            gap: 15px;
            margin-top: 30px;
            margin-left: 0;
            padding: 20px 30px;

            .access__bloc {
                display: flex;
                flex-direction: row;
                width: auto;
                height: 60px;
                margin-top: 0;
                margin-left: 20px;
                text-align: left;
                position: relative;
                color: $color-text;
                gap: 10px;

                svg {
                    width: 60px;
                    height: 60px;
                    
                    &.access__ligne {
                        position: absolute;
                        bottom: 0;
                        right: 5px;
                        width: 40px;
                        height: 10px;
                        margin: 0;
                    }
                }
            }
        }
    
        &__button {
            width: 40px;
            height: 40px;
        }
    
        #ff-stream-1 {
            width: 800px;
            margin-left: 0;

            .ff-item {
                width: 195px!important;
                .ff-img-holder {
                    height: 150px;
                }

                &#ff-uid-1 {
                    transform: translateX(0) !important;
                }

                &#ff-uid-2 {
                    transform: translateX(210px) !important;
                }

                &#ff-uid-3 {
                    transform: translateX(420px) !important;
                }
            }
            
            .ff-item-cont {
                height: 260px!important;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .home-slideshow {
        .slideshow {
            height: 340px;

            &__navigation {
    
                &__btn {
                    bottom: 20px;
    
                    &--prev {
                        bottom: 20px;
                        right: 165px;
                    }

                    &--next {
                        right: 115px;
                    }
                }
            }
        }
    }

    .access {
        &:before {
            left: calc(50% + 150px);
        }
        
        &__blocs {
            gap: 38px 30px;
            margin: 60px 15px 68px;
        }
    }

    .news {
        padding: 60px 0 78px;
        
        &:before, &:after {
            left: calc(50% - 490px);
            z-index: 0;
        }

        &__container {
            &:before {
                top: 100px;
                right: -200px;
            }
        }

        &__subtitle {
            margin-top: -4px;
        }

        &__swiper {
            margin-top: 30px;
        }

        &__link {
            &.swiper-slide-next {
                pointer-events: none;
            }
        }
    
        &__navigation {
            top: 25px;
        }

        &__buttons {
            position: relative;
            z-index: 1;
        }
    }

    .home-events {
        &__container {
            gap: 15px;
            padding-top: 105px;
        }

        &__swiper {
            &--image {
                width: 300px;
                height: 200px;
                min-width: 300px;
            }
    
            &--content {
                margin-bottom: 30px !important;
                max-width: 270px;
                margin-left: 30px !important;
                height: 50px !important;
            }
        }
    
        &__image {
            width: 270px;
    
            &--notimg {
                width: 270px;
            }
        }

        &__date {
            bottom: 20px;

            p {
                width: 60px;
                height: 60px;
                font-size: toRem(20);

                span {
                    font-size: toRem(16);
                }
            }
        }

        &__header {
            left: 40px;
        }

        &__subtitle {
            margin-top: -4px;
        }

        &__navigation {
            left: 220px;
            top: 22px;
        }

        &__buttons {
            gap: 15px;
            margin-left: 30px;
            flex-direction: column-reverse;
        }

        &__button {
            width: 270px;
        }
    }

    .social {
        margin: 105px 0 80px;

        &:before {
            width: 350px;
            height: 285px;
            background-size: 350px;
            left: calc(50% + 60px);
            top: -70px;
        }

        &__subtitle {
            margin-top: -4px;
        }

        &__wall {
            margin-top: 7px;
        }

        &__rs {
            top: unset;
            bottom: 200px;
            right: unset;
            left: 10px;
        }

        &__buttons {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            height: auto;
            margin-top: 90px;

            .access  {

                &__bloc {
                    margin-left: 0;
                }
            }
        }

        #ff-stream-1 {
            width: 300px;
            height: 1260px;
            margin-left: 0;

            .ff-item {
                width: 300px!important;
                .ff-img-holder {
                    height: 215px;
                }

                &#ff-uid-1 {
                    transform: translateX(0) !important;
                }

                &#ff-uid-2 {
                    transform: translateX(0) !important;
                    transform: translateY(420px) !important;
                }

                &#ff-uid-3 {
                    transform: translateX(0) !important;
                    transform: translateY(840px) !important;
                }
            }
            
            .ff-item-cont {
                height: 355px !important;
            }
        }
    }
}
