//=================================================================================================
// Wrapper for listing of cards in archive templates
//================================================================================================= 
.listing-cards {
    padding-top: 1.5*$gl-gutter;
    transition: $duration ease-in-out;
    margin: 0;

    &[data-cpt="contacts"] {
        margin: 0 -15px;
    }
}
