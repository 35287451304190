.header {
    position: fixed;
    right: 0;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 10;
    box-shadow: 0 0 1px rgba(27,35,54,.08),1px 1px 10px rgba(27,35,54,.08);
    height: 100px;
    background-color: $color-white;

    &__container {
        display: flex;
        justify-content: space-between;
        height: 100%;
    }

    &__link {
        width: 300px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        position: relative;
        z-index: 1;

        &:before {
            position: absolute;
            content: " ";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $color-white;
            box-shadow: $shadow;
            border-radius: 0px 0px 30px 30px;
            z-index: -1;
        }
    }

    &__title {
        font-size: 0;
        line-height: 0;
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    display: flex;
    align-items: center;
    gap: 15px;

    &__item {
        width: 40px;
        height: 40px;
        background-color: $color-second;
        margin: 0;
        padding: 0;
        border: none;

        svg {
            fill: $color-dark;
            width: 100%;
            height: 100%;
        }

        &:hover, &:focus {
            background-color: $color-fifth;

            svg {
                fill: $color-dark;
            }
        }
    }

    #accessconfig {
        max-height: 40px;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        &__link {
            width: 240px;
            height: 190px;

            svg {
                width: 170px;
                height: 110px;
            }
        }
    }

    .tools {
        gap: 10px;
    }
}


// 960
@media screen and (max-width: $medium) {
    .header {
        position: relative;
        
        &__link {
            width: 300px;
            height: 200px;
            z-index: 0;

            svg {
                width: 200px;
                height: 130px;
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .header {
        height: 70px;
        background-color: $color-dark;

        &__link {
            position: absolute;
            top: 70px;
            width: 300px;
            height: 160px;

            svg {
                width: 170px;
                height: 110px;
            }
        }
    }
}
