.contact-details-v2 {

    // Grid
    .contact-list {
        display: flex;
        align-items: stretch;

        &__item {
            flex: 0 1 25%;

            .contact-badge + .contact-badge {
                margin-top: 20px;
            }
        }
    }

    // Component title
    .contact-title {
        margin-top: 0;
    }

    // Component button
    .contact-buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        // Style to change into button--secondary
        .button {
            padding: 10px 30px;

            &--reverse {
                border-color: $color-text;
                color: $color-text;
                background-color: $color-bg--transparent;

                &:hover, &:focus {
                    background-color: $color-dark;
                    color: $color-white;
                }
            }

            &--no-icon {
                padding: 10px 30px;
                height: 50px;
            }
        }
    }

}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .contact-details-v2 {
        // Grid
        .contact-list {
            &__item {
                flex: 1 1 0;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .contact-details-v2 {

        // Grid
        .contact-list {
            flex-wrap: wrap;

            &__item {
                margin-top: 20px;

                &:first-child {
                    flex: 1 1 100%;
                    margin-top: 0;
                    display: flex;
                    align-items: center;

                    .contact-badge {
                        flex: 1 1 0;

                        + .contact-badge {
                            margin-top: 0;
                        }
                    }
                }
            }

        }
    }

}


// 640
@media screen and (max-width: $small) {

    .contact-details-v2 {

        // Grid
        .contact-list {

            &__item {
                margin-top: 20px;
                flex: 1 1 100%;

                &:first-child {
                    flex: 1 1 100%;
                    flex-direction: column;
                    align-items: stretch;

                    .contact-badge {
                        + .contact-badge {
                            margin-top: 20px;
                        }
                    }
                }
                
                &--additional {
                    padding-left: 34px;
                    margin-top: 10px;
                }
            }

        }
    }

}
