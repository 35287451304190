.bloc-content--404 {
    margin: 0 0 40px;

    h2 {
        margin: 50px 0;
        border: 0;
        font-size: 8.5rem;
        display: flex;
        justify-content: center;
        text-align: center;
        color: $color-dark;

        &:before, &:after {
            display: none!important;
        }
    }

    p {
        font-size: $font-size--5;
        text-align: center;
    }
}

.search--404 {
    width: 970px;

    .search-container {
        box-shadow: $shadow;
        border-radius: 50px;
    }

    .searchInput {
        color: $color-dark;
        z-index: 1;
        border: 0px;
        background-color: $color-white;
        border-radius: 50px;
        
        &::placeholder {
            color: $color-dark;
        }
    }
}

.grid-center-noGutter.container__buttons {
    margin: 30px 0;
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .search--404 {
        width: 100%;
    }
}

// 640
@media screen and (max-width: $small) {

    .bloc-content--404 {
        h2 {
            font-size: 7rem;
        }
    }
}

