.nav-main {
    z-index: 0;
    margin-left: -20px;

    // Container menu
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
    }

    // Main menu
    .menu {
        display: flex;
        flex-wrap: wrap;
        transition: $duration ease-out;

        &__item {

            &.menu-item-has-children {
                &:hover, &:focus-within {
                    .submenu {
                        display: flex;
                        justify-content: center;
                    }
                }
            }

            &:nth-child(2) {
                .menu__link:before {
                    right: 22px;
                }
            }

            &:nth-child(3) {
                .menu__link:before {
                    right: 30px;
                }
            }
    
            &:last-child {
                .menu__link {
                    background-color: $color-third;
                    padding: 0 50px 0 30px;
                    width: 120px;

                    &:before {
                        right: 40px;
                    }
                }
            }

            &:hover {
                .menu__link {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }   
        
        &__link {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            height: 100px;
            width: 105px;
            box-sizing: content-box;
            padding: 0 30px;
            color: $color-dark;
            font-weight: 700;
            font-size: toRem(20);
            text-transform: uppercase;

            span {
                font-weight: 300;
                margin-bottom: -5px;
            }

            &:before {
                position: absolute;
                content: "";
                width: 40px;
                height: 10px;
                right: 24px;
                bottom: 15px;
                mask-image: url(/wp-content/themes/st-colomban/assets/src/images/sprite-images/Pictos/ligne_ar.svg);
                mask-repeat: no-repeat; 
                background-color: $color-dark;
                opacity: 0;
                transition: all $duration;
            }
        }
    }

    // Sub menu
    .submenu {
        display: none;
        position: absolute;
        left: 0;
        top: 100px;
        z-index: 1;
        width: 100%;
        padding: 30px 15px;
        background: $color-dark;
        box-shadow: $shadow;

        &__container {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }

        &__nav {
            width: 100%;
            column-count: 2;
            column-gap: 20px;
            max-width: 810px;
        }

        &__item {
            display: inline-flex;
            width: 385px;
            margin: -1px; // Fix space beetween inline-flex
            padding: 0;
            line-height: initial;
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            font-weight: 700;
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 13px 25px 13px 0;
            color: $color-white;

            &:before {
                position: absolute;
                content: "";
                width: 0;
                height: 5px;
                left: 0;
                top: 20px;
                mask-image: url(/wp-content/themes/st-colomban/assets/src/images/sprite-images/Pictos/ligne_ar.svg);
                mask-repeat: no-repeat;
                mask-size: 20px;
                background-color: $color-fifth; 
                transition: all $duration;
            }

            &::after {
                content: '\f345';
                font: normal 20px/1 dashicons;
                position: absolute;
                right: 0;
                color: $color-white;
                transition: all $duration;
            }

            &:hover {
                color: $color-fifth;
                padding-left: 30px;

                &:before {
                    width: 20px;
                }

                &:after {
                    color: $color-fifth;
                }
            }

            &--grandchild {
                display: flex;
                padding: 17px 10px 17px 15px;
                border-bottom: 0;
                font-size: $font-size--text-small;
                font-weight: $font-weight;
                text-transform: initial;
                line-height: initial;

                &::before {
                    display: none;
                }
            }
        }
    }
    
    // For responsive
    &__button, &__icon, &__close, .submenu__close {
        display: none;
    }

}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main {
        margin-left: -5px;

        .menu {
            &__item {
                &:nth-child(2) {
                    .menu__link:before {
                        right: 18px;
                    }
                }

                &:nth-child(3) {
                    .menu__link:before {
                        right: 26px;
                    }
                }

                &:last-child {
                    .menu__link {
                        padding: 25px 20px;
                        width: 160px;
                        box-sizing: border-box;

                        &:before {
                            right: 24px;
                        }
                    }
                }
            }

            &__link {
                font-size: toRem(18);
                line-height: toRem(28);
                padding: 0 15px;

                &:before {
                    right: 20px;
                }
            }
        }

        .submenu {
            &__nav {
                max-width: 665px;
                column-gap: 35px;
            }

            &__item {
                width: 315px;
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    // if menu open (no scroll on body)
    body.js-active-navmain {
        overflow: hidden;
    }    

    .nav-main {
        position: relative;

        // If menu open
        &.js-active-menu {
            
            // Container menu
            .nav-main__container {
                left: 0;
            }
        }

        // Container menu
        &__container {
            position: fixed;
            top: 0;
            left: -100vw;
            right: 100%;
            z-index: 990;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 20px;
            max-width: inherit;
            width: 100%;
            height: 100vh;
            background-color: $color-dark;
            padding: 40px 0;
            transition: $duration;
        }
        
        // Burger menu responsive - Open menu
        &__button {
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            min-height: 100px;
            padding: 0;
            margin: 0;
            margin-left: 28px;
            background-color: transparent;
            border: 0;
            border-radius: 0;
            font-weight: 700;
            font-size: toRem(18); 
            color: $color-dark;
            text-transform: uppercase;
            transition: $duration;
            cursor: pointer;

            &:before {
                position: absolute;
                content: "";
                width: 40px;
                height: 40px;
                left: -12px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 50%;
                background-color: $color-third;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                color: $color-dark;
                background-color: transparent;
                border-color: transparent;

                &:before {
                    background-color: $color-fifth;
                }
            }
        }

        // Button icon (Burger menu responsive - Open menu)
        &__icon {
            position: relative;
            top: -1px;
            display: block;
            width: 17px;
            height: 2px;
            background: $color-dark;
            transition: $duration;

            &:before, 
            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background: $color-dark;
                transition: $duration;
            }

            &:before {
                top: 5px;
            }

            &:after {
                top: -5px;
            } 
        }

        // Burger menu responsive - Close menu
        &__close {
            position: relative;
            z-index: 10;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            min-height: 35px;
            margin: -10px 35px 0 615px;
            padding: 10px;
            background-color: transparent;
            border: 1px solid $color-white;
            border-radius: $border-radius--round;
            transition: all $duration ease-in-out;

            svg {
                width: 15px;
                height: 15px;
                margin: 0;
                fill: $color-white;
                transition: all $duration ease-in-out;
            }

            &.submenu-open {
                border: 1px solid $color-white;

                svg {
                    fill: $color-white;
                }
            }

            &:hover,
            &:focus {
                background-color: $color-white;
                border-color: $color-white;

                svg {
                    fill: $color-dark;
                }
            }
        }

        // Main menu
        .menu {
            flex-direction: column;
            flex-wrap: nowrap;
            overflow-y: auto;
            width: 620px;
            padding: 0;
            gap: 21px;

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%!important;
    
                &.menu-item-has-children {

                    &::after {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        color: $color-dark;
                        transition: all $duration;
                    }
    
                    &:focus-within .submenu, 
                    &:hover .submenu {
                        justify-content: flex-start;
                    }
    
                    // If sub menu open
                    &.js-active-submenu {   
                        .submenu {
                            right: 0;
                        }
                    }
                }

                &:last-child {
                    .menu__link {
                        background-color: transparent;
                        padding: 15px 0;
                    }

                    &:after {
                        display: flex;
                    }
                }
    
                &:hover, &:focus {
                    background-color: $color-bg--transparent;
    
                    .menu__link {
                        color: $color-white;
                    }
                }
            }

            &__link {
                justify-content: flex-start !important;
                max-width: inherit;
                width: fit-content!important;
                height: auto;
                padding: 15px 0;
                color: $color-white;
                margin-left: 0!important;

                &:before {
                    background-color: $color-fifth;
                    opacity: 1;
                    right: unset!important;
                    left: calc(100% + -32px)!important;
                    bottom: 10px!important;
                }

                &::after {
                    display: none;
                }
            }
        }

        // Sub menu
        .submenu {
            left: inherit;
            top: 0;
            right: 100%;
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 35px;
            width: 100vw;
            height: 100vh;
            padding: 40px 0 60px 0;
            background-color: $color-dark;
            transition: $duration;

            &::after {
                display: none;
            }

            // Burger menu responsive - Close submenu
            &__close {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                width: 35px;
                height: 35px;
                margin: -10px 0 0 0;
                padding: 0;
                position: relative;
                left: calc(50% + -310px);
                background-color: transparent;
                border: 1px solid $color-white;
                border-radius: $border-radius--round;
                transition: $duration;

                &:hover,
                &:focus {
                    background-color: $color-white;
                    border-color: $color-white;

                    svg {
                        fill: $color-dark;
                    }
                }

                svg {
                    width: 35px;
                    height: 35px;
                    fill: none;
                    fill: $color-white;
                    transition: $duration;
                }
            }

            &__nav {
                display: flex;
                flex-direction: column;
                max-width: 620px;
                padding: 0;
                overflow-y: auto;
            }

            &__link {
                color: $color-white;
                padding-left: 30px;

                &:before {
                    left: 0;
                    width: 20px;
                }

                &::after {
                    display: none;
                }
            }
        }
    }

    // Admin 
    .admin-bar {
        .nav-main {
            &__container, .submenu {
                padding-top: 70px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .nav-main {
        &__close {
            margin: -10px 40px 0 300px;
        }

        &__button {
            min-height: 70px;
            color: $color-white;
            margin-left: 6px;

            &:hover, &:focus {
                color: $color-white;
            }
        }

        .menu {
            width: 300px;
        }

        .submenu {
            &__nav {
                max-width: 300px;
            }

            &__close {
                left: calc(50% - 150px);
            }

            &__item {
                max-width: 300px;
            }
        }
    }
}
