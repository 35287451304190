.cover {
    position: relative;
    margin-top: 100px;

    &:before {
        position: absolute;
        content: "";
        width: 310px;
        height: 255px;
        right: -100px;
        bottom: -200px;
        background-image: url(/wp-content/themes/st-colomban/assets/src/images/sprite-images/home/bg_intro.svg);
        background-repeat: no-repeat;
        z-index: 1;
    }

    &__image-wrapper {
        width: 100%;
        height: 575px;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background: $color-white;
        padding: 10px 0 30px;
    }

    &__title {
        font-weight: 800;
        font-size: toRem(45);
        line-height: toRem(50);
        margin: 0 0 10px;
    }

    &__lead-wrapper {
        max-width: 1050px;

        p {
            margin: 20px 0 0;
            font-weight: 400;
            font-size: toRem(20);
            line-height: toRem(25);
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1500 - Responsive pour les feuilles décorative
@media screen and (max-width: 1500px) {
    .cover::before {
        left: calc(50% + 540px);
    }
}

// 1200
@media screen and (max-width: $large) {
    .cover {
        &:before {
            left: calc(50% + 380px);
        }

        &__image-wrapper {
            height: 460px;
        }

        &__lead-wrapper {
            max-width: 850px;
        }
    }

}

// 960
@media screen and (max-width: $medium) {
    .cover {
        margin-top: 0;

        &:before {
            left: calc(50% + 315px);
            width: 250px;
            height: 205px;
            background-size: 250px;
            bottom: -150px;
        }

        &__image-wrapper {
            height: 380px;
        }

        &__lead-wrapper {
            max-width: 100%;
        }

        &__title {
            font-size: toRem(38);
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__image-wrapper {
            height: 350px;
        }

        &__title {
            font-size: toRem(32);
            line-height: toRem(32);
        }

        &__lead-wrapper p {
            font-size: toRem(18);
        }
    }
}
